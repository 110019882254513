import { ConnectWallet } from "@thirdweb-dev/react";
import "./styles/Home.css";

export default function Home() {
  return (
    <main>
        
      <div className="flexrowclose">
        <h1 className="SNAPS">🫰🚫🧢SNAPSNOCAPS🫰🚫🧢</h1>
      </div>
      <div className="flexcolumnDRO border sidemargin">
      <div className="flexcolumnDR">
        <h3>Full-Stack Web3 Developer</h3>
        <h3>Multimedia Artist</h3> 
        <h3>Aspiring Polymath</h3>
      </div>
      <div className="flexcolumnDR">
        <h3>Entrepreneur</h3>
        <h3>Philosopher</h3>
      </div>
      <div className="flexcolumnDR">
        <h3>Music Producer</h3>
        <h3>Consultant</h3>
        <h3>Fashion Designer</h3>
      </div>
      </div>

      <br></br>

      <div className="flexcolumnDRA sidemargin">
        <div className="flexrowDC border padding sidemargin">
        <div className="flexcolumn">
          <h2>AVAXolotls - A True Passion-Project</h2>
          <a href="https://avaxolotls.com">
            <img 
              className="image"
              src="avaxolotls.png"
              alt="Avaxolotls NFT collection"
            />
          </a>
          <p>222 Customizable Axolotl PFPs</p>
        </div>

        <div className="flexcolumn sidemargin">
        
        <p className="border padding">AVAXolotls has been the driving force pushing me to learn web3 development, and I simply wouldn't be here today without them. Although I did not draw the art, I curated it, marketed it, and developed most of the smart contracts in the ecosystem. They are the flagship NFT collection I manage. Soon, AVAXolotls will be able to search for treasure which you can unbox, earning NFT & token rewards. One of the token rewards can be used to customize your AVAXolotls in various ways, including swapping traits between axolotls, naming your axolotls, & more.</p>
        </div>
        </div>
        <div className="flexcolumn border padding sidemargin">
          <h2>A Love Letter to Lines</h2>
          <a href="https://bullet.exchange/collections/66737ddcedf8e910bb777948">
            <img 
              className="imagewide"
              src="ALLTL3.png"
              alt="View on Bullet"
            />
          </a>
          <a href="https://bullet.exchange/collections/66737ddcedf8e910bb777948">
            <img 
              className="imagewide sidemargin"
              src="ALLTL2.png"
              alt="View on Bullet"
            />
          </a>
          <p>Abstract line-art banner ERC-404 art collection</p>
        </div>

      </div>

      <div className="flexcolumnDRA sidemargin">
        <div className="flexcolumn border paddingXL">
        <h2>$LINES on DEX Screener</h2>
        <div  id="dexscreener-embed">
          <iframe src="https://dexscreener.com/avalanche/0x6192c697f49817a25d50Bba1fE299ad6f528F17C?embed=1&theme=dark&trades=0&info=0"
          title="$SNAP on DEX Screener"
          className="border DEX"
          >
          </iframe>
        </div>
        <p>100 $LINES<br></br>= 1 ALLTL NFT</p>
        </div>

        <div className="flexcolumn border padding sidemargin">
          <h2>IN YOUR FACE</h2>
          <a href="https://campfire.exchange/collections/0x409ea5f7e98bffee8b6fb9f7ce7b222a674630fc">
            <img 
              className="imagelarge"
              src="IYF.gif"
              alt="IN YOUR FACE NFT collection"
            />
          </a>
          <p>Cyberpunk neo-glitch animations atop photography</p>
        </div>

      </div>



      <div className="flexcolumnDRA sidemargin">

        <div className="flexcolumn border padding">
            <h2>Turbulent Typography</h2>
            <a href="https://campfire.exchange/collections/0x52952db99cb55a187ac42d861afe973a135765f8">
              <img 
                className="imagewide"
                src="TT.png"
                alt="Turbulent Typography NFT collection"
              />
            </a>
            <p>Unique typography atop psychedelic, turbulent flows</p>
        </div>
        
        <div className="flexcolumn border padding">
            <h2>TOPOGRAPHY</h2>
            <a href="https://campfire.exchange/collections/0xb7735c95271cfcbdcb06b73d071ea461e893c462">
              <img 
                className="imagemedium"
                src="T.png"
                alt="TOPOGRAPHY NFT collection"
              />
            </a>
            <p>An exploration into topographics</p>
        </div>

      </div>
    </main>
     );
}
